import ReactGA from 'react-ga4';

export const initGA = () => {
  ReactGA.initialize('G-7L01DYHWNN');
  console.log('GA initialized');
};

export const logPageView = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  console.log('Pageview logged:', window.location.pathname);
}; 