import React, { useState, useEffect } from 'react';
import './ChatPage.css';
import Navbar from '../components/Navbar';
import MobileNavbar from '../components/MobileNavbar';
import { Footer } from '../components/footer';
import { useNavigate } from 'react-router-dom';
import { FaShieldAlt, FaPlus } from 'react-icons/fa';
import axios from 'axios';

// Gemini API config
const GEMINI_API_KEY = process.env.REACT_APP_GEMINI_API_KEY;
const GEMINI_API_URL = 'https://generativelanguage.googleapis.com/v1beta/models/gemini-pro:generateContent';

/**
 * ChatPage Component
 */
const ChatPage = () => {
    // ---------- STATE ----------
    const [messages, setMessages] = useState([]);
    const [userInput, setUserInput] = useState('');
    const [showWelcome, setShowWelcome] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [thinkingStage, setThinkingStage] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [isAskingName, setIsAskingName] = useState(false);

    // Doorstep scenario Q&A state
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [doorstepAnswers, setDoorstepAnswers] = useState({});

    // Add new state for follow-up question
    const [showFollowUpQuestion, setShowFollowUpQuestion] = useState(false);

    const navigate = useNavigate();

    // Pre-made questions
    const preMadeQuestions = [
        "Someone knocked on my door and charged me too much for work. What should I do?",
        "I received a call from this number. How do I check if it's a scam?",
        "I suspect my email was leaked. What should I do?",
        "I'm worried about old accounts I'm not using anymore. Should I delete them?",
        "Someone sent me a link to a virus scanner. Is it safe?",
        "I got a notification that my password might be compromised. How do I check?",
        "Someone claims they found my info on a data broker site. How do I remove it?"
    ];

    // Suspicious keywords
    const scamKeywords = [
        "doorstep","elderly","contractor","repairs","overcharged","cash","home improvement","roof",
        "driveway","garden","urgent work","lottery","cheque","IRS","fees","money","bank details",
        "password","credit card","wire transfer","breach","scam","leaked","suspicious",
        "unknown number","international","government","payment","caller ID","spoofed","fraud",
        "urgent","threat","compromise"
    ];

    // ---------- EFFECTS & LIFECYCLE ----------

    // Load from localStorage
    useEffect(() => {
        const saved = localStorage.getItem('chatMessages');
        if (saved) {
            try {
                const parsed = JSON.parse(saved);
                if (Array.isArray(parsed)) {
                    setMessages(parsed);
                }
            } catch (err) {
                console.error('Error loading chatMessages:', err);
            }
        }
    }, []);

    // Save chat to localStorage on each update
    useEffect(() => {
        if (messages.length > 0) {
            localStorage.setItem('chatMessages', JSON.stringify(messages));
        }
    }, [messages]);

    // For responsiveness
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Fetch user location on mount
    useEffect(() => {
        const fetchUserLocation = async () => {
            try {
                const resp = await axios.get('https://ipapi.co/json/');
                if (resp.data) {
                    setCity(resp.data.city || '');
                    setCountry(resp.data.country_name || '');
                }
            } catch (error) {
                console.error('Location fetch error:', error);
                setCity('');
                setCountry('');
            }
        };
        fetchUserLocation();
    }, []);

    // Listen for "startNameScan" => data broker removal
    useEffect(() => {
        const handleStartNameScan = () => {
            const fName = document.getElementById('scanFirstName')?.value || '';
            const lName = document.getElementById('scanLastName')?.value || '';

            if (!fName || !lName) {
                const invalids = document.querySelectorAll('.scan-input:invalid');
                invalids.forEach(inp => inp.classList.add('shake'));
                setTimeout(() => invalids.forEach(inp => inp.classList.remove('shake')), 600);
                return;
            }

            setThinkingStage('Initiating your Data Broker Scan...');

            setTimeout(() => {
                navigate('/scanning', {
                    state: {
                        firstName: fName,
                        lastName: lName,
                        city: city || 'Unknown',
                        country: country || 'Unknown'
                    }
                });
                setThinkingStage('');
                setMessages(prev => [
                    ...prev,
                    {
                        role: 'assistant',
                        content: `
                            <div class="confirmation-message">
                                Your Data Broker Scan has been initiated. 
                                We'll email you the results shortly.
                            </div>
                        `
                    }
                ]);
                setIsAskingName(true); // Hide input area
            }, 1200);
        };

        document.addEventListener('startNameScan', handleStartNameScan);
        return () => document.removeEventListener('startNameScan', handleStartNameScan);
    }, [city, country, navigate]);

    // Listen for free advice “doorstepProtect” event
    useEffect(() => {
        const handleDoorstepProtect = () => {
            setMessages(prev => [
                ...prev,
                {
                    role: 'assistant',
                    content: `
                        <div class="confirmation-message">
                            Thank you for requesting free local advice & cost estimates.
                            <br />We'll provide typical price ranges for home repair in your area.
                            <br /><br />
                            <em>Stay safe, and never pay cash upfront to unknown contractors.</em>
                        </div>
                    `
                }
            ]);
        };

        document.addEventListener('doorstepProtect', handleDoorstepProtect);
        return () => document.removeEventListener('doorstepProtect', handleDoorstepProtect);
    }, []);

    // Update the follow-up answer effect
    useEffect(() => {
        const handleFollowUp = async (event) => {
            const isYes = event.detail;
            
            // Clear previous chat history
            setMessages([]);
            
            // Add a small delay before showing the new message
            await new Promise(resolve => setTimeout(resolve, 500));
            
            // Add AI response based on answer
            const response = isYes ? 
                "Given the multiple suspicious contacts, I strongly recommend running a free data broker scan. This will help identify and remove your exposed information." :
                "Even with fewer incidents, it's wise to check if your information is exposed. I recommend our free data broker scan as a preventive measure.";

            // Add the response message
            setMessages([{ 
                role: 'assistant', 
                content: `<div class="ai-analysis">${response}</div>` 
            }]);

            // Wait before showing the data broker CTA
            await new Promise(resolve => setTimeout(resolve, 2000));
            
            // Then show the data broker CTA
            setMessages(prev => [...prev, { 
                role: 'assistant', 
                content: buildDataBrokerCTA() 
            }]);
        };

        document.addEventListener('answerFollowUp', handleFollowUp);
        return () => document.removeEventListener('answerFollowUp', handleFollowUp);
    }, [city, country]);

    // ---------- HELPER FUNCTIONS ----------

    // Check if a user query suggests doorstep scenario
    const isDoorstepScenario = (text) => {
        const lowerText = text.toLowerCase();
        return (
            lowerText.includes('door') ||
            lowerText.includes('elderly') ||
            lowerText.includes('knocked') ||
            lowerText.includes('home improvement') ||
            lowerText.includes('overcharged')
        );
    };

    // Highlight suspicious keywords in text
    const highlightScamKeywords = (text) => {
        let highlighted = text;
        scamKeywords.forEach(keyword => {
            const regex = new RegExp(`\\b${keyword}\\b`, 'gi');
            highlighted = highlighted.replace(regex, `<span class="highlight">${keyword}</span>`);
        });
        return highlighted;
    };

    // Fallback if Gemini is missing
    const handleStaticResponses = (msg) => {
        const lowerMsg = msg.toLowerCase();
        if (isDoorstepScenario(lowerMsg)) {
            // door scenario
            return `
                🚨 Doorstep Scam Alert!

                Someone overcharging or scamming for home repairs is common.
                1. Ask for references or licenses.
                2. Get multiple estimates before paying.
                3. Be wary of demands for immediate cash.
                4. If you feel unsafe, call the authorities.

                We can provide **free** cost estimates, so you know the fair price.
            `;
        } else if (lowerMsg.includes('scam')) {
            return `
                🚨 Beware of Potential Scams
                1. Never share personal info or pay upfront fees.
                2. Verify the caller ID or email domain.
                3. Check official gov sites for scam lists.
            `;
        }
        // fallback general
        return `
            🔐 General Security Advice

            Keep your personal data safe:
            1. Avoid revealing sensitive info.
            2. Use strong, unique passwords.
            3. Enable 2FA.
        `;
    };

    // Build the Gemini prompt
    const buildDoorstepPrompt = (msg, city, country) => `
        You are a community adviser for doorstep scams in ${city}, ${country}.
        The user says: "${msg}"

        Provide:
        - Warm, clear steps to avoid overpaying
        - Basic local contacts
        - No mention of paid data removal
        - A kind, reassuring tone
    `;

    // The main "fetchGeminiResponse" logic
    const fetchGeminiResponse = async (userMsg) => {
        if (!GEMINI_API_KEY) {
            console.warn("No Gemini API key. Using fallback...");
            return handleStaticResponses(userMsg);
        }

        try {
            let prompt;
            if (isDoorstepScenario(userMsg)) {
                // Build a custom prompt for doorstep
                prompt = buildDoorstepPrompt(userMsg, city || 'YourCity', country || 'YourCountry');
            } else {
                // Normal data removal scenario
                prompt = `
                    The user says: "${userMsg}"
                    Provide a short, helpful answer about scam or data security.
                    If not referencing door/elderly, mention free data broker scanning from CleanData.me
                    with no upfront cost.
                `;
            }

            const res = await fetch(`${GEMINI_API_URL}?key=${GEMINI_API_KEY}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    contents: [{ parts: [{ text: prompt }] }],
                    generationConfig: {
                        temperature: 0.7,
                        topK: 40,
                        topP: 0.95,
                        maxOutputTokens: 1024
                    }
                })
            });
            if (!res.ok) throw new Error(`HTTP Error: ${res.status}`);

            const data = await res.json();
            if (data.candidates && data.candidates[0]?.content?.parts?.[0]?.text) {
                return data.candidates[0].content.parts[0].text;
            }
            return handleStaticResponses(userMsg);

        } catch (err) {
            console.error("Gemini fetch error:", err);
            return handleStaticResponses(userMsg);
        }
    };

    // Typewriter effect for text
    const typeWriterEffect = async (analysisHTML, speed = 10) => {
        setMessages(prev => [...prev, { role: "assistant", content: "" }]);

        let typed = "";
        const chars = analysisHTML.split("");
        const punctuationSet = new Set([".", "!", "?", ":", ";"]);

        for (let i = 0; i < chars.length; i++) {
            typed += chars[i];
            setMessages(prev => {
                const newArr = [...prev];
                const lastIndex = newArr.length - 1;
                if (newArr[lastIndex].role === "assistant") {
                    newArr[lastIndex] = { ...newArr[lastIndex], content: typed };
                }
                return newArr;
            });

            if (punctuationSet.has(chars[i])) {
                await new Promise(r => setTimeout(r, 30));
            } else {
                await new Promise(r => setTimeout(r, speed));
            }
        }
    };

    // Format the final response from Gemini
    const formatGeminiResponse = async (text, userMsg) => {
        if (isDoorstepScenario(userMsg)) {
            const typedAnalysis = `
                <div class="ai-analysis">
                    <div class="analysis-header">
                        <span class="icon">🛡️</span>
                        <h3>Doorstep Protection Advice</h3>
                    </div>
                    <div class="analysis-content" style="font-size:1.05rem; line-height:1.6;">
                        ${text.replace(/\n/g, "<br />")}
                    </div>
                </div>
            `;
            return { typedAnalysis, immediateTools: "", finalCTA: buildDoorstepCTA() };
        } else {
            // Get tool suggestions
            const toolSuggestions = await fetchGeminiResponse(buildToolSuggestionPrompt(userMsg));
            let suggestedTools = [];
            try {
                const cleanJson = toolSuggestions.trim().replace(/```json|```/g, '');
                suggestedTools = JSON.parse(cleanJson);
            } catch (e) {
                console.error('Failed to parse tool suggestions:', e);
                const toolNames = ["Delete Accounts", "Data Broker Check", "Password Checker", 
                                 "Virus Scanner", "Check Your Email", "Scam AI Detector", "Area Code Lookup"];
                suggestedTools = toolNames.filter(tool => toolSuggestions.includes(tool));
            }

            // 1. First, show the analysis
            const typedAnalysis = `
                <div class="ai-analysis">
                    <div class="analysis-header">
                        <span class="icon">🛡️</span>
                        <h3>Security Analysis</h3>
                    </div>
                    <div class="analysis-content">
                        ${text.replace(/\n/g, "<br />")}
                    </div>
                </div>
            `;

            // 2. Then show relevant tools
            const allTools = [
                {
                    path: '/delete-account',
                    icon: '🗑️',
                    title: 'Delete Accounts',
                    description: 'Remove unwanted online accounts securely'
                },
                {
                    path: '/location',
                    icon: '🔍',
                    title: 'Data Broker Check',
                    description: 'Find where your data is being sold'
                },
                {
                    path: '/password-check',
                    icon: '🔒',
                    title: 'Password Checker',
                    description: 'Test your password strength'
                },
                {
                    path: '/file-scan',
                    icon: '🛡️',
                    title: 'Virus Scanner',
                    description: 'Scan files for malware'
                },
                {
                    path: '/data-leak',
                    icon: '📧',
                    title: 'Check Your Email',
                    description: 'See if your email was leaked'
                },
                {
                    path: '/scamai',
                    icon: '🤖',
                    title: 'Scam AI Detector',
                    description: 'Detect potential scam messages'
                },
                {
                    path: '/area-codes',
                    icon: '📞',
                    title: 'Area Code Lookup',
                    description: 'Verify phone number locations'
                }
            ];

            const relevantTools = allTools.filter(tool => 
                suggestedTools.includes(tool.title)
            );

            const tools = relevantTools.length > 0 ? `
                <div class="immediate-tools" style="background: rgba(28, 28, 28, 0.95); border: 1px solid rgba(66, 255, 181, 0.3); border-radius: 12px; padding: 20px; margin: 20px 0;">
                    <div class="tools-header" style="display: flex; align-items: center; gap: 12px; margin-bottom: 20px; padding-bottom: 10px; border-bottom: 1px solid rgba(66, 255, 181, 0.2);">
                        <span style="color: #42ffb5;">🛡️</span>
                        <h3 style="color: #42ffb5; margin: 0; font-size: 1.2rem; font-weight: 600;">Recommended Security Tools</h3>
                    </div>
                    <div class="tools-list" style="display: grid; grid-template-columns: 1fr; gap: 12px;">
                        ${relevantTools.map(tool => `
                            <a href="${tool.path}" style="
                                background: rgba(66, 255, 181, 0.05);
                                border: 1px solid rgba(66, 255, 181, 0.2);
                                border-radius: 10px;
                                padding: 16px;
                                display: flex;
                                align-items: center;
                                gap: 15px;
                                transition: all 0.3s ease;
                                cursor: pointer;
                                text-decoration: none;
                                color: inherit;
                            ">
                                <span style="font-size: 1.6rem; min-width: 30px; text-align: center;">${tool.icon}</span>
                                <div>
                                    <div style="color: #42ffb5; font-size: 1.1rem; font-weight: 500; margin-bottom: 4px;">${tool.title}</div>
                                    <div style="color: #e0e0e0; font-size: 0.9rem; opacity: 0.9;">${tool.description}</div>
                                </div>
                            </a>
                        `).join('')}
                    </div>
                </div>
            ` : '';

            // 3. Finally, show the follow-up question
            const followUpQuestion = `
                <div style="
                    margin: 20px 0;
                    padding: 20px;
                    background: rgba(28, 28, 28, 0.95);
                    border: 1px solid #42ffb5;
                    border-radius: 12px;
                    text-align: center;
                ">
                    <p style="
                        font-size: 1.1rem;
                        color: #fff;
                        margin-bottom: 20px;
                    ">
                        Have you received multiple suspicious calls or emails recently?
                    </p>
                    <div style="
                        display: flex;
                        justify-content: center;
                        gap: 20px;
                    ">
                        <button 
                            onclick="document.dispatchEvent(new CustomEvent('answerFollowUp', {detail: true}))"
                            style="
                                min-width: 120px;
                                padding: 12px 30px;
                                background-color: #1a1a1a;
                                border: 2px solid #42ffb5;
                                border-radius: 8px;
                                color: #ffffff;
                                font-size: 1rem;
                                font-weight: 600;
                                cursor: pointer;
                                text-transform: uppercase;
                            "
                        >
                            Yes
                        </button>
                        <button 
                            onclick="document.dispatchEvent(new CustomEvent('answerFollowUp', {detail: false}))"
                            style="
                                min-width: 120px;
                                padding: 12px 30px;
                                background-color: #1a1a1a;
                                border: 2px solid #42ffb5;
                                border-radius: 8px;
                                color: #ffffff;
                                font-size: 1rem;
                                font-weight: 600;
                                cursor: pointer;
                                text-transform: uppercase;
                            "
                        >
                            No
                        </button>
                    </div>
                </div>
            `;
            
            return { 
                typedAnalysis,  // Step 1: Analysis
                immediateTools: tools,  // Step 2: Relevant Tools
                finalCTA: followUpQuestion  // Step 3: Follow-up Question
            };
        }
    };

    // Build the free advice CTA for doorstep
    const buildDoorstepCTA = () => `
        <div class="data-broker-alert doorstep-alert">
            <strong>Concerned about overpaying?</strong>
            <br />
            <p>
                We can give you <strong>free local cost estimates</strong> to ensure you pay
                a fair price for any home repairs or improvements. No payment required for this advice!
            </p>
            <button class="scan-button cta-upgrade" 
                    onclick="document.dispatchEvent(new CustomEvent('doorstepProtect'))">
                Request Free Local Advice
            </button>
        </div>
    `;

    // Build the data broker CTA
    const buildDataBrokerCTA = () => `
        <div class="data-broker-alert" style="margin-top: 25px;">
            <strong style="font-weight: bold;">Protect Your Personal Details</strong>
            <br />
            <em>Leaving info on broker sites can lead to <span class="highlight">scams</span>, 
            <span class="highlight">harassment</span>, or <span class="highlight">identity theft</span>.</em>
            <br /><br />
            <p>
                Our <strong style="color:#42ffb5;">Free Data Broker Scan</strong> (no charge) 
                will find & remove your info from top broker sites. 
                ${
                    (city && country) 
                        ? `We see you're in ${city}, ${country}` 
                        : (city ? `We see you're in ${city}` : (country ? `We see you're in ${country}` : ''))
                }.
                Share your First & Last name for a more accurate removal.
            </p>
            <div class="name-input-fullframe">
                <div class="name-input-content">
                    <div class="name-fields">
                        <div class="input-field">
                            <input 
                                type="text" 
                                id="scanFirstName" 
                                placeholder="First Name"
                                class="scan-input"
                                required
                                style="width: 100%; padding: 12px; background: rgba(45, 45, 45, 0.95); border: 1px solid rgba(66, 255, 181, 0.3); border-radius: 8px; color: #fff; font-size: 0.95rem;"
                            />
                        </div>
                        <div class="input-field">
                            <input 
                                type="text" 
                                id="scanLastName" 
                                placeholder="Last Name"
                                class="scan-input"
                                required
                                style="width: 100%; padding: 12px; background: rgba(45, 45, 45, 0.95); border: 1px solid rgba(66, 255, 181, 0.3); border-radius: 8px; color: #fff; font-size: 0.95rem;"
                            />
                        </div>
                    </div>
                    <div style="
                        width: 100%;
                        padding: 14px;
                        background: #42ffb5;
                        border-radius: 8px;
                        margin-top: 10px;
                        text-align: center;
                        cursor: pointer;
                        box-shadow: 0 4px 15px rgba(66, 255, 181, 0.3);
                    " onclick="document.dispatchEvent(new CustomEvent('startNameScan'))">
                        <span style="
                            color: #1a1a1a;
                            font-size: 1rem;
                            font-weight: 600;
                            text-transform: uppercase;
                            letter-spacing: 1px;
                        ">START FREE SCAN</span>
                    </div>
                    <div class="privacy-assurance" style="text-align: center; margin-top: 10px; color: #e0e0e0; font-size: 0.9rem;">
                        <p>No payment details needed now. Your privacy is our priority.</p>
                    </div>
                </div>
            </div>
        </div>
    `;

    // Add this function near your other helper functions
    const getOptionIcon = (option) => {
        const lowerOption = option.toLowerCase();
        
        // Work type icons
        if (lowerOption.includes('roof')) return '🏠';
        if (lowerOption.includes('driveway') || lowerOption.includes('paving')) return '🛣️';
        if (lowerOption.includes('tree') || lowerOption.includes('garden')) return '🌳';
        if (lowerOption.includes('painting')) return '🎨';
        if (lowerOption.includes('maintenance')) return '🔧';
        
        // Payment type icons
        if (lowerOption.includes('cash')) return '💰';
        if (lowerOption.includes('bank')) return '🏦';
        if (lowerOption.includes('payment plan')) return '📅';
        
        // Pressure tactics icons
        if (lowerOption.includes('immediate')) return '⚡';
        if (lowerOption.includes('discount')) return '💯';
        if (lowerOption.includes('area')) return '📍';
        
        // ID verification icons
        if (lowerOption.includes('id')) return '📇';
        if (lowerOption.includes('vehicle')) return '🚗';
        if (lowerOption.includes('business card')) return '📋';
        
        // Default icon for other options
        return '❓';
    };

    // Add this with your other helper functions
    const buildImmediateTools = () => {
        const tools = [
            {
                path: '/delete-account',
                icon: '🗑️',
                title: 'Delete Accounts',
                description: 'Remove unwanted online accounts securely'
            },
            {
                path: '/location',
                icon: '🔍',
                title: 'Data Broker Check',
                description: 'Find where your data is being sold'
            },
            {
                path: '/password-check',
                icon: '🔐',
                title: 'Password Checker',
                description: 'Test your password strength'
            },
            {
                path: '/file-scan',
                icon: '🛡️',
                title: 'Virus Scanner',
                description: 'Scan files for malware'
            },
            {
                path: '/data-leak',
                icon: '📧',
                title: 'Check Your Email',
                description: 'See if your email was leaked'
            },
            {
                path: '/scamai',
                icon: '🤖',
                title: 'Scam AI Detector',
                description: 'Detect potential scam messages'
            },
            {
                path: '/area-codes',
                icon: '📞',
                title: 'Area Code Lookup',
                description: 'Verify phone number locations'
            }
        ];

        return `
            <div class="immediate-tools">
                <div class="tools-header">
                    <span class="icon">🛡️</span>
                    <h3>Free Security Tools</h3>
                </div>
                <div class="tools-list">
                    ${tools.map(tool => `
                        <div class="tool-item" onclick="window.location.href='${tool.path}'">
                            <span class="tool-icon">${tool.icon}</span>
                            <div class="tool-content">
                                <div class="tool-title">${tool.title}</div>
                                <div class="tool-description">${tool.description}</div>
                            </div>
                        </div>
                    `).join('')}
                </div>
            </div>
        `;
    };

    // Update the Gemini prompt to be clearer and simpler
    const buildToolSuggestionPrompt = (userMsg) => `
        Based on this user concern: "${userMsg}"
        Select the most relevant tools (maximum 2) from this list:
        - "Delete Accounts"
        - "Data Broker Check"
        - "Password Checker"
        - "Virus Scanner"
        - "Check Your Email"
        - "Scam AI Detector"
        - "Area Code Lookup"

        Rules:
        - For email leaks/compromises -> "Check Your Email"
        - For unwanted accounts -> "Delete Accounts"
        - For suspicious calls -> "Area Code Lookup"
        - For data exposure -> "Data Broker Check"
        - For password issues -> "Password Checker"
        - For suspicious files -> "Virus Scanner"
        - For scam messages -> "Scam AI Detector"

        Return ONLY a JSON array with the exact tool names. Example:
        ["Check Your Email", "Data Broker Check"]
    `;

    // ---------- MAIN "sendMessage" ----------

    const sendMessage = async (input) => {
        try {
            setThinkingStage('Analyzing your security concern...');
            const geminiResp = await fetchGeminiResponse(input);
            setThinkingStage('');

            const { typedAnalysis, immediateTools, finalCTA } = await formatGeminiResponse(geminiResp, input);

            // Step 1: Show the analysis first
            await typeWriterEffect(typedAnalysis, 10);

            // Step 2: Show relevant tools after a delay
            if (immediateTools) {
                await new Promise(resolve => setTimeout(resolve, 2000));
                setMessages(prev => [...prev, { role: 'assistant', content: immediateTools }]);
            }

            // Step 3: Show follow-up question after another delay
            await new Promise(resolve => setTimeout(resolve, 2000));
            setMessages(prev => [...prev, { role: 'assistant', content: finalCTA }]);

        } catch (err) {
            console.error('sendMessage error:', err);
            setMessages(prev => [...prev, { role: 'assistant', content: 'An error occurred. Please try again.' }]);
        }
    };

    // Handle user input
    const handleSendMessage = async () => {
        if (!userInput.trim()) return;
        setMessages(prev => [...prev, { role: 'user', content: userInput }]);
        setUserInput('');
        setShowWelcome(false);

        await sendMessage(userInput);
    };

    // If user clicks a premade question
    const handlePreMadeQuestionClick = async (question) => {
        setMessages(prev => [...prev, { role: 'user', content: question }]);
        setUserInput('');
        setShowWelcome(false);

        await sendMessage(question);
    };

    // Clear chat
    const handleNewChat = () => {
        setMessages([]);
        setShowWelcome(true);
        setThinkingStage('');
        localStorage.removeItem('chatMessages');
        setIsAskingName(false);
    };

    // Render messages
    const renderMessages = () => {
        return messages.map((m, i) => (
            <div
                key={i}
                className={m.role === 'user' ? 'user-message' : 'bot-response'}
                dangerouslySetInnerHTML={{
                    __html: m.role === 'assistant'
                        ? highlightScamKeywords(m.content || '')
                        : m.content
                }}
            />
        ));
    };

    const showWelcomeMessage = showWelcome && messages.length === 0;

    const renderDoorstepQuestion = (question, questionNumber, totalQuestions) => {
        const formatOption = (option) => {
            // Add highlighting to key terms
            const highlightTerms = (text) => {
                const terms = ['roof', 'driveway', 'garden', 'tree', 'painting', 'maintenance'];
                let highlighted = text;
                terms.forEach(term => {
                    const regex = new RegExp(`(${term})`, 'gi');
                    highlighted = highlighted.replace(regex, `<span class="highlight">$1</span>`);
                });
                return highlighted;
            };

            return highlightTerms(option.replace(/['"\\]/g, '').trim());
        };

        return `
            <div class="doorstep-question">
                <div class="question-progress">
                    <div class="progress-dots">
                        ${Array(totalQuestions).fill(0).map((_, i) => 
                            `<span class="progress-dot ${i === questionNumber - 1 ? 'active' : ''}"></span>`
                        ).join('')}
                    </div>
                    <div class="question-counter">Question ${questionNumber} of ${totalQuestions}</div>
                </div>

                <div class="question-main">
                    <h2 class="question-title">${question.question}</h2>
                    
                    <div class="answer-buttons">
                        ${question.options.map(option => {
                            const formattedOption = formatOption(option);
                            return `
                                <button 
                                    class="answer-option" 
                                    onclick="document.dispatchEvent(new CustomEvent('answerDoorstepQuestion', 
                                        { detail: { answer: '${option.replace(/['"\\]/g, '')}' } }))">
                                    <div class="option-content">
                                        <span class="option-icon">${getOptionIcon(option)}</span>
                                        <span class="option-text">${formattedOption}</span>
                                    </div>
                                </button>
                            `;
                        }).join('')}
                    </div>
                </div>
            </div>
        `;
    };

    // Update getDoorstepQuestions to include more descriptive options
    const getDoorstepQuestions = () => [
        {
            id: 'workType',
            question: "What type of work did they say needs doing?",
            options: [
                "Roof repairs or inspection",
                "Driveway or paving work",
                "Tree cutting or garden maintenance",
                "House painting or decorating",
                "General property maintenance",
                "Other work (please specify)"
            ]
        },
        // ... rest of your questions
    ];

    const renderFinalAnalysis = (answers, city, country) => {
        return `
            <div class="analysis-section">
                <div class="analysis-header">
                    <span class="icon">🛡️</span>
                    <h2>Safety Analysis & Recommendations</h2>
                </div>
                
                <div class="analysis-content">
                    <div class="recommendation-box">
                        <h3>Key Points About Your Situation:</h3>
                        <ul>
                            <li>Work Type: <span class="highlight">${answers.workType}</span></li>
                            <li>Payment Request: <span class="highlight">${answers.payment}</span></li>
                            <li>Location: <span class="highlight">${city}, ${country}</span></li>
                        </ul>
                    </div>

                    <div class="warning-box">
                        <h3>⚠️ Important Safety Steps:</h3>
                        <ul>
                            <li>Never feel pressured to make immediate decisions</li>
                            <li>Always get multiple quotes in writing</li>
                            <li>Check trader credentials and reviews</li>
                            <li>Keep all documentation of interaction</li>
                        </ul>
                    </div>

                    <div class="contact-info">
                        <h3>Emergency Contacts:</h3>
                        <div class="contact-number">
                            <span>🚔 Police (non-emergency):</span>
                            <span>${country === 'United Kingdom' ? '101' : '911'}</span>
                        </div>
                        <div class="contact-number">
                            <span>📞 Trading Standards:</span>
                            <span>${country === 'United Kingdom' ? '0808 223 1133' : 'Local Consumer Protection'}</span>
                        </div>
                    </div>
                </div>
            </div>
        `;
    };

    const addMessage = (text, isUser = false) => {
        setMessages((prevMessages) => [...prevMessages, { text, isUser }]);
    };

    const showTypingIndicator = () => {
        addMessage('Typing...', false);
        setTimeout(() => {
            setMessages((prevMessages) => prevMessages.filter(msg => msg.text !== 'Typing...'));
        }, 2000);
    };

    return (
        <div className="chat-page">
            {/* Always show MobileNavbar on mobile, regular Navbar on desktop */}
            <div className="navbar-wrapper">
                {isMobile ? <MobileNavbar /> : <Navbar />}
            </div>

            {/* Header section */}
            <div className="top-section">
                <div className="raven-header">
                    <div className="raven-logo-section">
                        <FaShieldAlt className="raven-icon" />
                        <div className="raven-text">
                            <h1 className="raven-title">RavenAI Security</h1>
                            <p className="raven-subtitle">World Experts in Preventing Scams &amp; Identity Fraud</p>
                        </div>
                    </div>
                    <button className="new-chat-button" onClick={handleNewChat}>
                        <FaPlus /> New Chat
                    </button>
                </div>
            </div>

            {/* Main chat container */}
            <div className="chat-container">
                <div className="chat-history">
                    {showWelcomeMessage && (
                        <div className="welcome-message">
                            <h1 style={{
                                fontSize: '2.4rem',
                                fontWeight: 600,
                                background: 'linear-gradient(135deg, #42ffb5 0%, #2ee9a0 100%)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                marginBottom: '20px',
                                letterSpacing: '-0.5px',
                                textShadow: '0 2px 10px rgba(66, 255, 181, 0.3)'
                            }}>
                                Scam AI - Protect Your Data & Prevent Scams
                            </h1>
                            <p style={{
                                fontSize: '1.1rem',
                                lineHeight: 1.6,
                                color: '#e0e0e0',
                                marginBottom: '30px',
                                fontWeight: 400,
                                letterSpacing: '0.2px'
                            }}>
                                Greetings! I am Raven, your advanced cybersecurity & data privacy AI. 
                                I can help if you've been approached at your doorstep for overpriced work, 
                                or if you're worried about phone/email scams or data leaks.
                            </p>
                            <div className="pre-made-questions">
                                {preMadeQuestions.map((question, idx) => (
                                    <button
                                        key={idx}
                                        onClick={() => handlePreMadeQuestionClick(question)}
                                        className="question-button"
                                    >
                                        {question}
                                    </button>
                                ))}
                            </div>
                        </div>
                    )}
                    {renderMessages()}
                </div>

                {!isAskingName && (
                    <div className="input-area" style={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        padding: '10px 15px',
                        background: '#1a1a1a',
                        borderTop: '1px solid rgba(66, 255, 181, 0.2)',
                        display: 'flex',
                        gap: '8px',
                        zIndex: 1000,
                        paddingBottom: 'calc(10px + env(safe-area-inset-bottom))'
                    }}>
                        <input
                            type="text"
                            className="input-focus"
                            placeholder={window.innerWidth <= 768 ? "Ask a question..." : "Ask me about any security concern..."}
                            value={userInput}
                            onChange={(e) => setUserInput(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                            style={{
                                flex: 1,
                                minHeight: '40px',
                                padding: '12px',
                                background: 'rgba(45, 45, 45, 0.95)',
                                border: '1px solid rgba(66, 255, 181, 0.3)',
                                borderRadius: '8px',
                                color: '#ffffff',
                                fontSize: '14px'
                            }}
                        />
                        <button 
                            className="send-button" 
                            onClick={handleSendMessage}
                            style={{
                                padding: '12px 24px',
                                background: '#42ffb5',
                                color: '#1a1a1a',
                                border: 'none',
                                borderRadius: '8px',
                                fontWeight: 600,
                                cursor: 'pointer'
                            }}
                        >
                            Send
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ChatPage;
