import React, { useState, useEffect } from 'react';
import { FaEraser, FaStar } from 'react-icons/fa';
import { FiMenu, FiX } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import './MobileNavbar.css';
import { useAuth } from '../context/AuthContext';

const MobileNavbar = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const { user, logout, loading } = useAuth();

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
        if (isDropdownOpen) setDropdownOpen(false);

        // Toggle body class for scroll prevention
        if (!isMenuOpen) {
            document.body.classList.add('menu-open');
        } else {
            document.body.classList.remove('menu-open');
        }
    };

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleLogout = () => {
        try {
            logout();
            window.location.href = 'https://cleandata.me';
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    // Cleanup to ensure body scroll is enabled when component unmounts
    useEffect(() => {
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    if (loading) {
        // Optionally, render a loading indicator or placeholder
        return null;
    }

    return (
        <React.Fragment>
            {/* Navbar Header */}
            <header className="navbar-header">
                <div className="navbar-content">
                    <div className="logo-section">
                        <FaEraser className="logo-icon" />
                        <span className="navbar-title">
                            CleanData<span className="logo-subtitle">.me</span>
                        </span>
                    </div>
                    <button onClick={toggleMenu} className="menu-button">
                        {isMenuOpen ? <FiX className="menu-icon" /> : <FiMenu className="menu-icon" />}
                    </button>
                </div>
            </header>

            {/* Slide-In Menu */}
            <nav className={`slide-menu ${isMenuOpen ? 'open' : ''}`}>
                <ul className="menu-list">
                    <li className="menu-item">
                        <Link to="/" onClick={toggleMenu}>Home</Link>
                    </li>
                    <li className="menu-item">
                        <Link to="/pricing" onClick={toggleMenu}>Pricing</Link>
                    </li>
                    <li className="menu-item">
                        <Link to="/blog" onClick={toggleMenu}>Blog</Link>
                    </li>
                    <li className="menu-item">
                        <Link to="/contact" onClick={toggleMenu}>Contact</Link>
                    </li>

                    {/* Free Tools Button with Dropdown */}
                    <li className="menu-item">
                        <button className="free-tools-button" onClick={toggleDropdown}>
                            Free Tools
                        </button>
                        {isDropdownOpen && (
                            <ul className="dropdown-menu">
                                <li>
                                    <Link to="/delete-account" onClick={toggleMenu}>
                                        Delete Accounts
                                        <span className="free-badge-container mobile">
                                            <FaStar className="free-badge" />
                                            <span className="free-text">FREE</span>
                                        </span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/location" onClick={toggleMenu} className="data-broker-item">
                                        Data Broker Check
                                    </Link>
                                </li>
                                <li><Link to="/password-check" onClick={toggleMenu}>Password Checker</Link></li>
                                <li><Link to="/file-scan" onClick={toggleMenu}>Virus Scanner</Link></li>
                                <li><Link to="/data-leak" onClick={toggleMenu}>Check Your Email</Link></li>
                                <li><Link to="/scamai" onClick={toggleMenu}>Scam AI Detector</Link></li>
                                <li><Link to="/area-codes" onClick={toggleMenu}>Area Code Lookup</Link></li>
                            </ul>
                        )}
                    </li>

                    <li className="menu-item sign-in">
                        {user ? (
                            <button onClick={handleLogout} className="sign-out-button">
                                Sign Out
                            </button>
                        ) : (
                            <Link to="/login" onClick={toggleMenu}>
                                Sign In
                            </Link>
                        )}
                    </li>
                </ul>
            </nav>
        </React.Fragment>
    );
};

export default MobileNavbar;
