import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import laptopImage from '../assets/laptop-image.png';
import './LoginPage.css';
import Navbar from '../components/Navbar';
import { Footer } from '../components/footer';
import MobileNavBar from '../components/MobileNavbar';

const LoginPage = () => {
    const { login } = useAuth();
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [password, setPassword] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [isResetFlow, setIsResetFlow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Only scroll on desktop
        if (window.innerWidth > 1024) {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth'
            });
        }
    }, []);

    const handleSendOtp = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccessMessage('');

        try {
            const response = await fetch('https://cleandata.fly.dev/api/password/forgot', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
                credentials: 'include'
            });

            const data = await response.json();

            if (response.ok) {
                setOtpSent(true);
                setSuccessMessage('Verification code sent to your email');
            } else {
                setError(data.message || 'Failed to send verification code');
            }
        } catch (error) {
            setError('Network error. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccessMessage('');

        try {
            const response = await fetch('https://cleandata.fly.dev/api/password/reset', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, otp, newPassword: password }),
                credentials: 'include'
            });

            const data = await response.json();

            if (response.ok) {
                setSuccessMessage('Password reset successful! Please log in.');
                setTimeout(() => {
                    setIsResetFlow(false);
                    setOtpSent(false);
                }, 2000);
            } else {
                setError(data.message || 'Invalid code or password');
            }
        } catch (error) {
            setError('Network error. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccessMessage('');

        try {
            const result = await login(email, password);
            
            const params = new URLSearchParams(location.search);
            const returnUrl = params.get('returnUrl') || '/dashboard';
            
            navigate(returnUrl);
        } catch (error) {
            setError(error.message || 'Login failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="login-page-wrapper">
            {window.innerWidth <= 768 ? <MobileNavBar /> : <Navbar />}
            
            <div className="auth-container">
                <div className="auth-wrapper">
                    <div className="auth-content">
                        <div className="auth-header">
                            <h1 className="brand">Cleandata.me Pro</h1>
                            <h2 className="auth-title">
                                {isResetFlow ? 'Reset Password' : 'Welcome Back'}
                            </h2>
                            <p className="auth-subtitle">
                                {isResetFlow 
                                    ? 'Enter your email to receive a reset code' 
                                    : 'Sign in to manage your data protection'}
                            </p>
                        </div>

                        {successMessage && (
                            <div className="alert success" role="alert">
                                {successMessage}
                            </div>
                        )}

                        {error && (
                            <div className="alert error" role="alert">
                                {error}
                            </div>
                        )}

                        <form onSubmit={isResetFlow ? (otpSent ? handleVerifyOtp : handleSendOtp) : handleLogin}
                              className="auth-form">
                            <div className="form-field">
                                <label htmlFor="email">Email</label>
                                <input
                                    id="email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter your email"
                                    required
                                />
                            </div>

                            {(!isResetFlow || (isResetFlow && otpSent)) && (
                                <div className="form-field">
                                    <label htmlFor="password">
                                        {isResetFlow ? 'New Password' : 'Password'}
                                    </label>
                                    <input
                                        id="password"
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder={isResetFlow ? 'Enter new password' : 'Enter password'}
                                        required
                                    />
                                </div>
                            )}

                            {isResetFlow && otpSent && (
                                <div className="form-field">
                                    <label htmlFor="otp">Verification Code</label>
                                    <input
                                        id="otp"
                                        type="text"
                                        value={otp}
                                        onChange={(e) => setOtp(e.target.value)}
                                        placeholder="Enter verification code"
                                        required
                                    />
                                </div>
                            )}

                            <button 
                                type="submit" 
                                className={`auth-button ${loading ? 'loading' : ''}`}
                                disabled={loading}
                            >
                                {loading ? (
                                    <span className="spinner"></span>
                                ) : (
                                    isResetFlow ? (otpSent ? 'Reset Password' : 'Send Code') : 'Sign In'
                                )}
                            </button>
                        </form>

                        <div className="auth-links">
                            {!isResetFlow ? (
                                <>
                                    <button 
                                        onClick={() => setIsResetFlow(true)} 
                                        className="text-button"
                                    >
                                        Forgot password?
                                    </button>
                                    <span className="separator">•</span>
                                    <a href="http://www.cleandata.me/pricing" className="text-button">
                                        Create account
                                    </a>
                                </>
                            ) : (
                                <button 
                                    onClick={() => setIsResetFlow(false)} 
                                    className="text-button"
                                >
                                    Back to login
                                </button>
                            )}
                        </div>
                    </div>
                    
                    <div className="auth-image">
                        <img src={laptopImage} alt="Dashboard Preview" />
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default LoginPage;