import { useState } from "react";
import { 
  FaDownload, 
  FaShieldAlt, 
  FaBolt, 
  FaLock, 
  FaDesktop, 
  FaCog, 
  FaExclamationTriangle,
  FaVirus,
  FaUserShield,
  FaChartLine,
  FaTwitter,
  FaLinkedin,
  FaFacebook
} from "react-icons/fa";
import MobileNavbar from "../components/MobileNavbar";
import Navbar from "../components/Navbar";
import useWindowSize from "../hooks/useWindowSize";
import previewImage from "../images/image.png"; // Import the image
import "./FileScanPage.css";

const FileScanPage = () => {
  const [showUnavailableMessage, setShowUnavailableMessage] = useState(false);
  const { width } = useWindowSize();

  const features = [
    {
      icon: <FaShieldAlt />,
      title: "Real-Time Protection",
      description: "24/7 monitoring against malware, ransomware, and emerging threats"
    },
    {
      icon: <FaVirus />,
      title: "Advanced Threat Detection",
      description: "AI-powered scanning engine with 99.9% detection rate"
    },
    {
      icon: <FaBolt />,
      title: "Lightning Fast Performance",
      description: "Optimized scanning with minimal system impact"
    },
    {
      icon: <FaUserShield />,
      title: "Privacy Protection",
      description: "Secure your personal data and online activities"
    },
    {
      icon: <FaChartLine />,
      title: "Smart Analytics",
      description: "Detailed insights about your device's security status"
    },
    {
      icon: <FaLock />,
      title: "Military-Grade Security",
      description: "Enterprise-level protection for personal use"
    }
  ];

  const handleDownload = () => {
    setShowUnavailableMessage(true);
    setTimeout(() => setShowUnavailableMessage(false), 3000);
  };

  return (
    <div className="file-scan-page">
      {width <= 768 ? <MobileNavbar /> : <Navbar />}
      
      <div className="content">
        <h1>Protect Your Mac with CleanData.me</h1>
        <p className="subtitle">Advanced protection against modern threats</p>

        <div className="preview-container">
          <img 
            src={previewImage}
            alt="CleanData Software Preview" 
            className="software-preview"
          />
        </div>

        <div className="features-section">
          <h2>Why Choose CleanData?</h2>
          <div className="features-grid">
            {features.map((feature, index) => (
              <div key={index} className="feature-card">
                <div className="feature-icon">{feature.icon}</div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="cta-section">
          <h2>Start Protecting Your Device Today</h2>
          <p>Join thousands of users who trust CleanData for their security needs</p>
          <button className="download-button" onClick={handleDownload}>
            <FaDownload /> Download Virus Scanner
          </button>
        </div>

        {showUnavailableMessage && (
          <div className="unavailable-message">
            <FaExclamationTriangle />
            <p>Download feature is currently unavailable</p>
          </div>
        )}
      </div>

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-links">
            <a href="/privacy">Privacy Policy</a>
            <a href="/terms">Terms of Service</a>
            <a href="/help">Help Center</a>
          </div>
          <div className="social-links">
            <a href="#"><FaTwitter /></a>
            <a href="#"><FaLinkedin /></a>
            <a href="#"><FaFacebook /></a>
          </div>
          <div className="copyright">
            ©2025 CleanData.me. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  );
};

export default FileScanPage;
