import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FaShieldAlt, FaExternalLinkAlt, FaPhoneAlt, FaExclamationTriangle, FaMapMarkedAlt, FaBuilding } from 'react-icons/fa';
import Navbar from '../components/Navbar';
import MobileNavbar from '../components/MobileNavbar';
import { Footer } from '../components/footer';
import './AreaCodeMainPage.css';
import areaCodesData from '../data/area-codes.json';
const { areaCodes } = areaCodesData;

const AreaCodeMainPage = () => {
  const location = useLocation();
  const pageTitle = "US Area Code Directory - Scam Reports & Safety Information";
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCodes, setFilteredCodes] = useState(areaCodes);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    
    if (term) {
      const filtered = areaCodes.filter(code => 
        code.areaCode.includes(term) ||
        code.location.toLowerCase().includes(term)
      );
      setFilteredCodes(filtered);
    } else {
      setFilteredCodes(areaCodes);
    }
  };

  const generateUniqueKey = (areaCode) => {
    return `${areaCode.areaCode}-${areaCode.location.replace(/\s/g, '')}`;
  };

  const generateAreaCodeUrl = (areaCode) => {
    return `/area-codes/${areaCode}`;
  };

  const infoCards = [
    {
      icon: <FaPhoneAlt className="info-icon" />,
      title: "Scam Protection",
      description: "Learn about common phone scams and how to protect yourself from fraudulent calls.",
      color: "#526DE6"
    },
    {
      icon: <FaExclamationTriangle className="info-icon" />,
      title: "Fraud Alerts",
      description: "Get real-time updates on reported scam numbers and emerging phone threats.",
      color: "#FF4757"
    },
    {
      icon: <FaMapMarkedAlt className="info-icon" />,
      title: "Area Information",
      description: "Detailed information about US area codes, including location and historical data.",
      color: "#2ed573"
    }
  ];

  return (
    <div className="page-container">
      {isMobile ? <MobileNavbar /> : <Navbar />}
      <div className="area-code-main">
        <Helmet>
          <title>{pageTitle} | Phone Safety Guide</title>
          <meta 
            name="description" 
            content="Comprehensive guide to US area codes, known phone scams, and fraud prevention. Protect yourself from phone-based threats and scams." 
          />
          <link rel="canonical" href={`https://app.cleandata.me${location.pathname}`} />
        </Helmet>

        <div className="hero-section">
          <FaShieldAlt className="hero-icon pulse" />
          <h1>US Area Code Safety Guide</h1>
          <p className="hero-subtitle">
            Protect yourself from phone scams with our comprehensive area code directory. 
            Get detailed information about locations, reported scams, and safety ratings.
          </p>

          <div className="search-section">
            <div className="search-container">
              <div className="search-wrapper">
                <input
                  type="text"
                  value={searchTerm}
                  onChange={handleSearch}
                  placeholder="Search area code or location..."
                  className="search-input"
                  aria-label="Search area codes"
                />
              </div>
              {searchTerm && (
                <div className="search-results" role="listbox">
                  {filteredCodes.map((code) => (
                    <Link 
                      key={generateUniqueKey(code)}
                      to={generateAreaCodeUrl(code.areaCode)}
                      className="search-result-item"
                      role="option"
                    >
                      <span className="area-code">{code.areaCode}</span>
                      <div className="result-meta">
                        <span className="location">{code.location}</span>
                        <span className={`safety-badge ${code.safetyRating.toLowerCase()}`}>
                          {code.safetyRating}
                        </span>
                        <FaExternalLinkAlt className="external-link-icon" />
                      </div>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <section className="info-cards">
          {infoCards.map((card, index) => (
            <div 
              key={index} 
              className="info-card"
              style={{ borderColor: card.color }}
            >
              {card.icon}
              <h3>{card.title}</h3>
              <p>{card.description}</p>
            </div>
          ))}
        </section>

        <section className="all-area-codes">
          <h2>All Area Codes</h2>
          <div className="area-codes-grid">
            {filteredCodes.map((code) => (
              <Link 
                key={generateUniqueKey(code)}
                to={generateAreaCodeUrl(code.areaCode)}
                className="area-code-card"
              >
                <h3>{code.areaCode}</h3>
                <p className="location">{code.location}</p>
                <div className="area-code-info">
                  <span className={`safety-badge ${code.safetyRating.toLowerCase()}`}>
                    {code.safetyRating}
                  </span>
                  <div className="quick-links">
                    {code.activeScamAlerts?.length > 0 && (
                      <span className="alert-count">
                        <FaExclamationTriangle /> {code.activeScamAlerts.length} Active Alerts
                      </span>
                    )}
                    {code.legitimateCallers?.government?.length > 0 && (
                      <span className="govt-numbers">
                        <FaBuilding /> {code.legitimateCallers.government.length} Verified Numbers
                      </span>
                    )}
                  </div>
                  <span className="risk-level">Risk Level: {code.riskLevel}</span>
                  <span className="view-details">
                    View Details <FaExternalLinkAlt />
                  </span>
                </div>
              </Link>
            ))}
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default AreaCodeMainPage; 