import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTachometerAlt,
    faUserEdit,
    faExchangeAlt,
    faListAlt,
    faLifeRing,
    faPlus,
    faChartLine
} from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';
import profileImage from '../assets/profile.jpg';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Sidebar = () => {
    const location = useLocation();
    const { user } = useAuth();
    
    console.log('Sidebar component - user data:', user);

    const getDisplayName = () => {
        if (!user) return 'Welcome Back!';

        // Try to get first_name and last_name from raw_user_meta_data
        const metaData = user.raw_user_meta_data || {};
        const firstName = metaData.first_name || user.first_name || '';
        const lastName = metaData.last_name || user.last_name || '';

        if (firstName || lastName) {
            return `${firstName} ${lastName}`.trim();
        }

        // If no name is found, use email username
        return user.email ? user.email.split('@')[0] : 'Welcome Back!';
    };

    const calculateSubscriptionStatus = () => {
        return 'protected';
    };

    const subscriptionStatus = calculateSubscriptionStatus();

    return (
        <div className="sidebar">
            <div className="sidebar-profile">
                <div className="profile-pic-wrapper">
                    <img src={profileImage} alt="User" className="profile-pic" />
                    <div className="online-status">
                        <div className="status-dot"></div>
                    </div>
                </div>
                <div className="profile-info">
                    <h3 className="user-name">{getDisplayName()}</h3>
                    <div className="status-badge">
                        <span className="status-icon"></span>
                        {subscriptionStatus.charAt(0).toUpperCase() + subscriptionStatus.slice(1)}
                    </div>
                </div>
            </div>
            <nav className="sidebar-nav">
                <Link to="/dashboard" className={`nav-item ${location.pathname === '/dashboard' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faTachometerAlt} className="nav-icon" />
                    <span>Dashboard</span>
                </Link>
                <Link to="/data-removals" className={`nav-item ${location.pathname === '/data-removals' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faChartLine} className="nav-icon" />
                    <span>Live Reports</span>
                    <div className="status-indicator">
                        <span className="status-dot"></span>
                        Active
                    </div>
                </Link>
                <Link to="/edit-info" className={`nav-item ${location.pathname === '/edit-info' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faUserEdit} className="nav-icon" />
                    <span>Edit Your Info</span>
                </Link>
                <Link to="/change-plan" className={`nav-item ${location.pathname === '/change-plan' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faExchangeAlt} className="nav-icon" />
                    <span>Change Plan</span>
                </Link>
                <Link to="/sites" className={`nav-item ${location.pathname === '/sites' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faListAlt} className="nav-icon" />
                    <span>Sites We Cover</span>
                </Link>
                <Link to="/support" className={`nav-item ${location.pathname === '/support' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faLifeRing} className="nav-icon" />
                    <span>Support</span>
                </Link>
            </nav>
            <a 
                href="http://www.cleandata.me/pricing" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="add-family-member-button"
            >
                <FontAwesomeIcon icon={faPlus} className="button-icon" />
                <span>Add Family Member</span>
            </a>
        </div>
    );
};

export default Sidebar;