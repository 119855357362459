import axios from 'axios';

// !!!IMPORTANT!!! DO NOT CHANGE THIS URL
// Login server is separate from the dashboard API server
// Login server must remain at: https://cleandata.fly.dev
const AUTH_SERVER_URL = 'https://cleandata.fly.dev';

// Create axios instance with default config
const api = axios.create({
    baseURL: AUTH_SERVER_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    },
    // Add validation for status codes
    validateStatus: (status) => {
        return status >= 200 && status < 500;
    }
});

// Add request interceptor to include token in headers
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Add response interceptor to handle auth errors
api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            localStorage.removeItem('token');
        }
        return Promise.reject(error);
    }
);

export const login = async (email, password) => {
    try {
        const response = await api.post('/api/login', { email, password });
        
        if (response.data.token) {
            localStorage.setItem('token', response.data.token);
            return {
                user: response.data.user,
                token: response.data.token
            };
        }
        throw new Error('No token received from server');
    } catch (error) {
        console.error('Login error:', error.response?.data || error.message);
        throw error;
    }
};

export const getUser = async () => {
    const token = localStorage.getItem('token');
    if (!token) return null;

    try {
        const response = await api.get('/api/user-data');
        return response.data;
    } catch (error) {
        if (error.response?.status === 401) {
            localStorage.removeItem('token');
        }
        return null;
    }
};

export const logout = () => {
    try {
        localStorage.removeItem('token');
    } catch (error) {
        console.error('Logout error:', error);
    }
};

export const validateToken = () => {
    const token = localStorage.getItem('token');
    return !!token;
};

export default api;
