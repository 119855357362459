import io from 'socket.io-client';

const SOCKET_URL = process.env.NODE_ENV === 'development' 
    ? 'http://localhost:5001'  // Development server URL
    : (process.env.REACT_APP_SOCKET_URL || 'https://dashboard-api-server.fly.dev');

export const socket = io(SOCKET_URL, {
    transports: ['websocket', 'polling'],  // Added polling as fallback
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 1000,
    timeout: 10000,
    autoConnect: false, // Prevent automatic connection
});

// Add connection handling
socket.on('connect_error', (error) => {
    console.log('Socket connection error:', error);
    // You can add custom error handling here
});

socket.on('connect', () => {
    console.log('Socket connected successfully');
});

socket.on('disconnect', (reason) => {
    console.log('Socket disconnected:', reason);
});

// Export a function to manually connect when needed
export const connectSocket = () => {
    if (!socket.connected) {
        socket.connect();
    }
};

// Export a function to disconnect
export const disconnectSocket = () => {
    if (socket.connected) {
        socket.disconnect();
    }
};
